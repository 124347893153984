
<template>
  <div class="fullCalBox mt-2">
    <div class="">
      <div class="flex-wrap flex">
        <div class="" v-for="(status, index) in employeeList" :key="index" :class="`${index === 0 ? 'left-0 sticky' : ''}`">
          <div @click="toggleActiveAndInactiveB(index)" v-if="activeUer !== status.userDetailId">
            <Chip v-if="status.fullName.toLowerCase() === 'all'" :class="`${status.selected ? 'bg-gray3' : '' } py-1 cursor-pointer`"   :chipText="status.fullName"/> 
            <Chip v-else :class="`${status.selected ? 'activeView' : '' } py-1 cursor-pointer`"   :chipText="status.fullName"/>   </div>  
          <div @click="toggleActiveAndInactiveB(index)" v-else>
            <Chip  :class="`${status.selected ? 'activeView' : '' } py-1 cursor-pointer`"  :chipText="'Me'"/>  </div>   
        </div>
      </div>
      <div class="flex  flex-wrap text-text1 mb-1">
          <div class="flex  flex-wrap lg:flex-nowrap w-full justify-between">
            <div v-if="selectedView !== 'month'" class=" heading-2  w-96 mb-1 md:mb-0">

              <div  class=" flex whitespace-nowrap">
                <div  @click="selectedViewVal('time')" >
                <Chip :class="`${displayWiseFilter === 'time' ? 'activeView' : 'cursor-pointer' } py-1`" style="font-size: 16px" :chipText="`Time wise jobs`"/>
                </div>
                <div  @click="selectedViewVal('user')" >
                <Chip   :class="`${displayWiseFilter === 'user' ? 'activeView' : 'cursor-pointer' } py-1 `" style="font-size: 16px" :chipText="`User wise jobs`"/>
                </div>
            </div>
            </div>
            <div class=" hidden lg:block heading-2  w-full font-bold  text-center ">
              <div class="xl:ml-70">
                <span><span v-if="!hideBtn" class="text-primary font-semibold heading-4 cursor-pointer " @click="selectedDateByPicker()">
              Go to Today
              </span></span>
              <span class=" whitespace-nowrap">{{showTiltle}}</span>
              <span @keydown.esc="selectedDatePicker = false" @click.stop.prevent="selectedDatePicker = true" class="ml-4 text-primary cursor-pointer"><i class="fa-solid fa-pen-to-square"></i></span>
              </div>
            </div>
            <div class="text-center flex sm:justify-end flex-wrap sm:flex-nowrap gap-1 mb-1 md:mb-0">
              <div @click="selectDays('agendaDay')">
              <Chip  :class="`${selectedView === 'timelineDay' ||  selectedView === 'agendaDay'  ? 'activeView' : '' } py-1 cursor-pointer`" style="font-size: 16px" :chipText="`1 Day`"/>
              </div>
              <div @click="selectDays('month')">
              <Chip :class="`${selectedView === 'month' ? 'activeView' : '' } py-1 cursor-pointer`" style="font-size: 16px" :chipText="`Month`"/>
              </div>
              <div @click="goDateCall('prev')">
              <Chip class="py-1 cursor-pointer activeView" style="font-size: 16px" :iconClass="'fa fa-chevron-left'" :chipText="``"/>
              </div>
              <div @click="goDateCall('next')">
              <Chip class="py-1 cursor-pointer activeView" style="font-size: 16px" :iconClass="'fa fa-chevron-right'" :chipText="``"/>
              </div>
            </div>
            <div class=" block lg:hidden  heading-2  w-full font-bold  text-center ">
              <div class="xl:ml-72">
                <span><span v-if="!hideBtn" class="text-primary font-semibold heading-4 cursor-pointer " @click="selectedDateByPicker()">
              Go to Today
              </span></span>
              <span class=" whitespace-nowrap">{{showTiltle}}</span>
              <span @keydown.esc="selectedDatePicker = false" @click.stop.prevent="selectedDatePicker = true" class="ml-4 text-primary cursor-pointer"><i class="fa-solid fa-pen-to-square"></i></span>
              </div>
            </div>
          </div>
      </div>
      <div class="cust_card bg-white pb-40" style="overflow: auto; height: 100vh;">
        <div :style="`height: ${elementHeight}px`" class=" relative" >
          <div id="timelineparentId" class="p-4" v-if="!isLoad">
            <div v-if="selectedUserView === true && groupsDynamic.length > 0">
              <time-line :items="itemsDynamic" :groups="groupsDynamic" :options="options" :view="'user'" />
            </div>
            <div v-if="selectedTimeView === true">
              <time-line :items="itemsDynamic" :groups="groupsStatic" :options="options"  :view="'time'" />
            </div>
          </div>
          <div v-else class="p-4 top-0  bottom-0 left-0 right-0 absolute w-full h-full bg-white flex justify-center items-center">
            <div class="">
                <div class="text-gray4 heading-4 p-2 flex items-center">
                  <div class="loader2 ease-linear mr-2"></div>
                  Loading.....
                </div>
              </div>
          </div>
        </div>
        <div style="width: 100%; height: 700px"  class="card bg-white rounded-xl p-4 mb-4">
          <VisitMapLocation :scheduledList="eventList" :unscheduleventList="unscheduleventList" :lastEmpData="empArray" :isUnSchedule="false" class="left-container"/>
          <!-- <VisitMapLocation v-if="false" :scheduleData="eventList" :lastEmpData="empArray" :isUnSchedule="false" class="left-container"/> -->
        </div>
        <!-- {{eventList}}eventList.length -->
        <div class="card bg-white rounded-xl p-4 mb-4">
        <ScheduleListing :listTitle="'Schedule List'" :scheduled="true"  :scheduleData="eventList"  />
          </div>
        <div  class="card bg-white rounded-xl p-4 mb-4">
        <ScheduleListing :listTitle="'Unschedule list'" :scheduled="false"  :scheduleData="unscheduleventList"  />
          </div>
      </div>
    </div>
    
    <div class="popup_overlay px-4" v-if="createJobPopup">
      <div class="custom_dialog rounded-xl" style="width: 400px; overflow: auto; ">
        <div class="relative bg-white p-2 text-center">
          <div class=" font-medium text-black heading-3 p-4">
            {{newJobObj.dateTitle}}
          </div>
          <div class="divider"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="createTask()">
            <span class=" font-semibold text-primary heading-5">Create new Task</span>
          </div>
          <div class="dividerLight"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="createNewJob()">
            <span class=" font-semibold text-primary heading-5">Create new Job</span>
          </div>
          <div class="dividerLight"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="existingJob()">
            <span class=" font-semibold text-primary heading-5">Add to existing Job</span>
          </div>
          <div class="dividerLight"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="cancelPopup()">
            <span class=" font-semibold text-primary heading-5">Cancel</span>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="selectedDatePicker">
      <div class="custom_dialog rounded-xl" >
        <DateTimepicker v-click-outside="closeDatePicker"
                v-model="currentViewDate"
                :title="''"
                :showOnlyDate="true"
                :hideGoToday="true"
                :hideBtn="true"
                :roundedCard="true"
                @input="updateDate()"
                >
            </DateTimepicker>
      </div>
    </div>
    <div class="popup_overlay relative px-4" v-if="dublicate_Assign" >
    <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="font-bold">
          <p v-if="moveItem.obj.entityType === 'job'" class="heading-3 text-text1">This job visit is already assigned to the selected user</p>
          <p v-if="moveItem.obj.entityType === 'request'" class="heading-3 text-text1">This request is already assigned to the selected user</p>
          
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'OK'" @buttonAction="assingedOk()"/>
        </div>
    </div>
    </div>
    <SearchJob v-if="searchJobPopup" />
    <ScheduleExistingJobAdd v-if="openAddSchedulePage" :detail="selectedExisitngObject" :startTime="this.newJobObj.selectedStartTime" :endTime="this.newJobObj.selectedEndTime" />
  </div>
</template>

<script>
// import Vue from 'vue'
import {FilterPermissions} from '@/utils/Permissions.js'
import MyJobApp from "@/api/MyJobApp.js";
// import {ReFormatDate} from '@/utils/validation'
import moment from 'moment'
import TimeLine from './timeLine.vue'
import Button from '../components/globalButton.vue'
import Chip from '../components/chip.vue'

import {getDisplayText} from '@/utils/convertDateAndTime.js'
import DateTimepicker from '../components/dateTimepicker.vue'
import SearchJob from './components/searchJob.vue';
import ScheduleExistingJobAdd from './components/scheduleExistingJobAdd.vue';
import VisitMapLocation from './components/visitMapLocation.vue';
import ScheduleListing from './components/scheduleListing.vue';
// Vue.use(FullCalendar)
export default {
    components: {
        TimeLine,
        Button,
        Chip,
        DateTimepicker,
        SearchJob,
        ScheduleExistingJobAdd,
        VisitMapLocation,
        ScheduleListing
    },
  data() {
    return {
      elementHeight: 0,
      eventList: [],
      unscheduleventList: [],
      empArray: [],
      mainview: "ondayview",
      selectedExisitngObject: null,
      isLoad: true,
      applyedUserFilters: '',
      userFilterlist: '',
      dublicate_Assign: false,
      initialStart: '',
      initialEnd: '',
      updateScheConfirm: false,
      moveItem : null,
      moveCallback: null,
      selectedObj: '',
      selectedDisplayView: 'user',
      selectedUserView: false,
      selectedTimeView: false,
      activeUer: 0,
      employeeList: [],
      userTimeFilter: 'time',
      displayWiseFilter: 'user',
      selectedDatePicker: false,
      currentViewDate: null,
      hideBtn: true,
      openAddSchedulePage: false,
      searchJobPopup: false,
      createJobPopup: false,
      selectedView: 'agendaDay',
      showTiltle:'',
      scheduleStartDt: '',
      selectedDate: null,
      view: '',
      scheduleEndDt: '',
      newJobObj: {
        dateTitle: '',
        selectedStartTime: '',
        selectedEndTime: ''
      },
      itemsDynamic: [],
      groupsStatic: [
        {
          id: 'all',
          content:
            "<div style='float:right;'><p style='margin: 0'>" +
            "All" +
            "</p><div>",
          style: "width: 100px; text-align:center",
        }
      ],
      groupsDynamic: [],
      options: {
        snap: (date) => {
          // 15 minutes ke liye
          return Math.round(date / (15 * 60 * 1000)) * (15 * 60 * 1000);
        },
        onMove: this.onItemMove,
        onUpdate: this.onUpdateCall,
        showCurrentTime: true,
        orientation: "top",
        itemsAlwaysDraggable: false,
        horizontalScroll: false,
        zoomable: false,
        timeAxis: { scale: "hour", step: 1 },
        zoomMax: 150000000,
        // minHeight: "500px",
        // start: '2024-02-05',
        min: moment(new Date()).format('YYYY-MM-DD'),
        max: moment(new Date()).format('YYYY-MM-DD'),
        // editable: true,
        // hiddenDates: {
        //     start: '2024-02-05 00:00:00',
        //     end: '2020-02-05 23:59:00',
        //     repeat: 'daily'
        // },
        selectable: true,
        editable: {
            add: true,         // add new items by double tapping
            updateTime: true,  // drag items horizontally
            updateGroup: true, // drag items from one group to another
            remove: true,       // delete an item by tapping the delete button top right
            overrideItems: false,  // allow these options to override item.editable
            // itemsAlwaysDraggable: true
        }
      },
      dataLoaded: null
    }
  },
  props: [],
  created () {

    if (this.$route.query.subview !== undefined) {
      if (this.$route.query.subview !== '') {
        this.selectedDisplayView = this.$route.query.subview
      }
    }
    if (this.$route.query.etime !== undefined) {
      if (this.$route.query.etime !== '') {
        this.scheduleEndDt = this.$route.query.etime
          this.options.max = this.$route.query.etime
      }
    }
    if (this.$route.query.user !== undefined) {
      if (this.$route.query.user !== '') {
        this.applyedUserFilters = this.$route.query.user
           this.userFilterlist = this.$route.query.user.split(',')
      }
    }
    if (this.$route.query.stime !== undefined) {
      if (this.$route.query.stime !== '') {
        this.options.min = this.$route.query.stime
        this.scheduleStartDt = this.$route.query.stime
        this.startTime = this.$route.query.stime
        if (moment(new Date()).format('YYYY-MM-DD') === moment(this.$route.query.stime).format('YYYY-MM-DD')) {
          this.hideBtn = true
        } else {
          this.hideBtn = false
        }
      }
    }
    if (this.selectedDisplayView === 'user') {
      this.getUserFilter();
      this.selectedUserView = true
      this.displayWiseFilter = 'user'
      this.getTeamList();
      this.getDayOneJobVisist();
    } else {
      this.selectedUserView = false
      this.displayWiseFilter = 'time'
      this.getTeamList()
      this.getUserFilter()
      this.getJoblist();
    }
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
    if (this.$route.query.stime !== undefined) {
      if (this.$route.query.stime !== '') {
        let todayDate = moment(new Date(this.$route.query.stime)).format('YYYY-MM-DD')
        let date = moment(todayDate)
        this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
      } else {
        let todayDate = moment(new Date()).format('YYYY-MM-DD')
        let date = moment(todayDate)
        this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
      }
    } else {
      let todayDate = moment(new Date()).format('YYYY-MM-DD')
      let date = moment(todayDate)
      this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
    }
    
  },
  mounted () {
     window.addEventListener('popstate', this.loadRouterWise);
     this.getUnSchJobs()
    document.body.style = 'overflow: hidden;'
    let d = new Date(this.options.max)
    let tomorrowDate =  d.setDate(d.getDate() + 1)
    tomorrowDate = moment(new Date(tomorrowDate).toString()).format('YYYY-MM-DD')
    this.options.max = tomorrowDate
    document.title = 'Schedule'
    this.$root.$on("closeScheduleJobSel", (response, isClose) => {
      if (!isClose) {
        this.selectedExisitngObject = response
        this.openAddSchedulePage = true
      }
      this.searchJobPopup = false
    });
    this.$root.$on('addExistingJobHandler', (response) => {
      if (response) {
        if (this.selectedDisplayView === 'time') {
          this.getTeamList()
          this.getJoblist()
        } else {
          this.getTeamList()
          this.getDayOneJobVisist()
        }
      }
      this.openAddSchedulePage = false
    })
    window.addEventListener('createJobPopup-localstorage-changed', (event) => {
      let obj = JSON.parse(event.detail.storage)
      let tempStartDate = null
      let tempEndDate = null
      let sheduleDate = new Date(obj.start)
      let d1 = new Date (sheduleDate)
      let d2 = new Date ( d1 )
      d2.setMinutes ( d1.getMinutes() + 30 );
      tempStartDate = d1
      tempEndDate = d2
      this.newJobObj.selectedStartTime = tempStartDate
        this.newJobObj.selectedEndTime = tempEndDate
        this.newJobObj.dateTitle = getDisplayText(this.newJobObj.selectedStartTime, this.newJobObj.selectedEndTime)
        this.createJobPopup = true    
    });
    
  },
  watch: {
    itemsDynamic: {
      handler () {
        if (this.itemsDynamic.length === 0 ) {
          this.eventList = []
        } else {
          setTimeout(() => {
            this.elementHeight =  document.getElementById('timelineparentId').offsetHeight
          }, 1000);

        }
      }
    },
    elementHeight: {
      handler () {
      }
    },
  },
  methods: {
    manageRouterQuery () {
      // console.log('check 1111111111111111111');
      this.$router.push({query: {view: this.mainview, subview: this.selectedDisplayView, user: this.userFilterlist.toString(), stime: this.scheduleStartDt, etime: this.scheduleEndDt}})
    },
    onUpdateCall (item, callback) {
          console.log("item", item);
          if (item.content != 'new item') {
            if (JSON.parse(item.id).entityType === 'job') {
          window.location.replace(`/job/visit/detail/${JSON.parse(item.id).visitId}`)
          }
          if (JSON.parse(item.id).entityType === 'task') {
            let getStartTime = JSON.parse(item.id).startTime
            let date = moment(new Date(getStartTime)).format('YYYY-MM-DD')
            window.location.replace(`/task/${JSON.parse(item.id).jobId}?date=${date}`)
          }
          if (JSON.parse(item.id).entityType === 'request') {
            window.location.replace(`/request/details/${JSON.parse(item.id).jobId}`)
          }
            callback(item);
          } else {
            let newJobTask = {
              openPopup: true,
              start: new Date(item.start ) 
            }
            localStorage.setItem('newJobTask', JSON.stringify(newJobTask))
                window.dispatchEvent(new CustomEvent('createJobPopup-localstorage-changed', {
                  detail: {
                    storage: localStorage.getItem('newJobTask')
                  }
                })); 
          }
        },
    onItemMove(item, callback) {
        // Store the initial position of the item before moving
        this.initialStart = item.start;
        this.initialEnd = item.end;
        // Open the custom popup for move confirmation
        // this.updateScheConfirm = true;  Assuming updateScheConfirm is the flag to show the popup
      if (item.className === 'task') {
        callback(null);
        return false
      }
      // Store the item and callback to be used later
        this.moveItem = item;
        this.moveCallback = callback;
        // console.log('item-----', item);
        // console.log('itemsDynamic-----', this.itemsDynamic);
        this.itemsDynamic.forEach(e=> {
          if (e.obj.visitId === item.obj.visitId && e.group !== item.group) {
            this.updateScheConfirm = true;
          }
        })

        if (!this.updateScheConfirm) {

          this.moveItem.content = `<div  style='float:right;'><p style='margin: 0'>${moment(new Date(this.moveItem.start)).format('HH:mm')} - ${moment(new Date(this.moveItem.end)).format('HH:mm')}</p><span style='font-size:11px; font-weight: 300; font-color:#eee;'>${this.moveItem.obj.title}(${this.moveItem.obj.entityType}#${this.moveItem.obj.jobId})<span><p style='margin: 0'>${this.moveItem.obj.summary.split('for')[0]}</p></div>`;
          this.updateDirect(true)
        } else {
          this.updateViSpecific(true)
        }   
    },
    assingedOk() {
      this.moveCallback(null);
      this.dublicate_Assign = false
    },
    updateViSpecific(shouldDelete) {
      let dublicateAssignee = false
      // let oldAssigneeIndex = 0
        if (shouldDelete) {
          let multpleMove = []
            const moveAllowed = true;

            if (moveAllowed) {
              this.moveItem.oldAssignee = this.moveItem.obj.userDetailId
                // console.log('Item moved 111111111:', this.moveItem);
                let updatedObj = this.moveItem            
                    this.moveItem.content = `<div  style='float:right;'><p style='margin: 0'>${moment(new Date(this.moveItem.start)).format('HH:mm')} - ${moment(new Date(this.moveItem.end)).format('HH:mm')}</p><span style='font-size:11px; font-weight: 300; font-color:#eee;'>${this.moveItem.obj.title}(${this.moveItem.obj.entityType}#${this.moveItem.obj.jobId})<span><p style='margin: 0'>${this.moveItem.obj.summary.split('for')[0]}</p></div>`;
                    
              updatedObj.obj.userDetailId = this.moveItem.group
              multpleMove.push(updatedObj)

              if (this.moveItem.className === "request") {
                this.itemsDynamic.forEach((e)=> {
                if (e.obj.jobId === this.moveItem.obj.jobId && e.id !== this.moveItem.id) {
                  this.updateScheConfirm = true;
                  e.start = this.moveItem.start
                  e.end = this.moveItem.end
                  e.content = `<div  style='float:right;'><p style='margin: 0'>${moment(new Date(this.moveItem.start)).format('HH:mm')} - ${moment(new Date(this.moveItem.end)).format('HH:mm')}</p><span style='font-size:11px; font-weight: 300; font-color:#eee;'>${this.moveItem.obj.title}(${this.moveItem.obj.entityType}#${this.moveItem.obj.jobId})<span><p style='margin: 0'>${this.moveItem.obj.summary.split('for')[0]}</p></div>`;
                  multpleMove.push(e) }
                  // oldAssigneeIndex = i
              })

              if (JSON.parse(this.moveItem.id).userDetailId !== this.moveItem.group) {
                 let findex = this.itemsDynamic.findIndex(e => e.obj.jobId === this.moveItem.obj.jobId && e.group === this.moveItem.group && this.moveItem.oldAssignee !== e.group)

                if (findex >= 0) {
                  dublicateAssignee = true
                }
              }
              } else {
                this.itemsDynamic.forEach((e)=> {
                if (e.obj.visitId === this.moveItem.obj.visitId && e.id !== this.moveItem.id) {
                    this.updateScheConfirm = true;
                    e.start = this.moveItem.start
                    e.end = this.moveItem.end
                    e.content = `<div  style='float:right;'><p style='margin: 0'>${moment(new Date(this.moveItem.start)).format('HH:mm')} - ${moment(new Date(this.moveItem.end)).format('HH:mm')}</p><span style='font-size:11px; font-weight: 300; font-color:#eee;'>${this.moveItem.obj.title}(${this.moveItem.obj.entityType}#${this.moveItem.obj.jobId})<span><p style='margin: 0'>${this.moveItem.obj.summary.split('for')[0]}</p></div>`;
                    multpleMove.push(e)
                    // oldAssigneeIndex = i
                  }
                })

                  if (JSON.parse(this.moveItem.id).userDetailId !== this.moveItem.group) {
                    let findex = this.itemsDynamic.findIndex(e => e.obj.visitId === this.moveItem.obj.visitId && e.group === this.moveItem.group && this.moveItem.oldAssignee !== e.group)
                    if (findex >= 0) {
                      dublicateAssignee = true
                    }
                  }

                
              }
                if (!dublicateAssignee) {
                  // console.log("oldAssigneeIndex---------------", oldAssigneeIndex);
                  // this.itemsDynamic[oldAssigneeIndex].group = this.moveItem.group
                  
                  this.itemsDynamic.forEach((e, i)=> {
                    if (e.id === this.moveItem.id) {
                      this.itemsDynamic.splice(i, 1, this.moveItem)
                    }
                  })
                  this.moveCallback(multpleMove);
                  this.updateVisitTimeData(JSON.stringify(this.moveItem))

                  // console.log("itemsDynamic---------------", this.itemsDynamic);
                } else {
                  this.dublicate_Assign = true
                }
            } else {
                this.moveItem.start = this.initialStart;
                this.moveItem.end = this.initialEnd;
                this.moveCallback(null);
            }
        } else {
            this.moveItem.start = this.initialStart;
            this.moveItem.end = this.initialEnd;
            this.moveCallback(null);
        }
        this.updateScheConfirm = false;
    },
    updateDirect() {
       this.moveCallback(this.moveItem);
       this.updateVisitTimeData(JSON.stringify(this.moveItem))
        this.updateScheConfirm = false;
    },
    updateVisitTimeData(data) {
      let obj = JSON.parse(data)
      console.log("DATA.START", obj.start);
      console.log("DATA.end", obj.end);
      let getData = {
        visitId: JSON.parse(obj.id).visitId,
        jobId: JSON.parse(obj.id).jobId,
        entityType: JSON.parse(obj.id).entityType,
        StartDateTime: new Date(obj.start).toUTCString(),
        EndDateTime: new Date(obj.end).toUTCString(),
        oldAssignee: obj.oldAssignee,
        newAssignee: obj.group
      }
      if (this.selectedDisplayView === 'user') {
        if (getData.entityType === 'job') {
          MyJobApp.updateVisitTime(
              {
                visitId: getData.visitId,
                visitStartDateTime: getData.StartDateTime,
                visitEndDateTime: getData.EndDateTime,
                oldAssignee: getData.oldAssignee,
                newAssignee: getData.newAssignee,
              },
              response => {
                // this.$root.$emit('refershJobList')
              localStorage.removeItem('selectedObj')
              // this.selectedDateByPicker(this.currentViewDate)
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              },
              () => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              }
          )
        }
        if (getData.entityType === 'request') {
          MyJobApp.RequestDragDropEvent(
              {
                requestId: getData.jobId,
                startTime: getData.StartDateTime,
                endTime: getData.EndDateTime,
                oldAssignee: getData.oldAssignee,
                newAssignee: getData.newAssignee,
              },
              response => {
                // this.$root.$emit('refershJobList')
              localStorage.removeItem('selectedObj')
              // this.selectedDateByPicker(this.currentViewDate)
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              },
              () => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              }
          )
        }
      }
      if (this.selectedDisplayView === 'time') {
        if (getData.entityType === 'job') {
          MyJobApp.updateVisitTime(
            {
              visitId: getData.visitId,
              visitStartDateTime: getData.StartDateTime,
              visitEndDateTime: getData.EndDateTime,
              oldAssignee: 0,
              newAssignee: 0,
            },
            response => {
              // this.$root.$emit('refershJobList')
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            localStorage.removeItem('selectedObj')
            // this.selectedDateByPicker(this.currentViewDate)
            },
            () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
          )
        }
        if (getData.entityType === 'request') {
          MyJobApp.RequestDragDropEvent(
              {
                requestId: getData.jobId,
                startTime: getData.StartDateTime,
                endTime: getData.EndDateTime,
                oldAssignee: 0,
                newAssignee: 0,
              },
              response => {
                // this.$root.$emit('refershJobList')
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              localStorage.removeItem('selectedObj')
              // this.selectedDateByPicker(this.currentViewDate)
              },
              () => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              }
          )
        }
      }
    },
    selectDays (view) {
      if (view === 'month') {
        this.$router.push({name: 'monthView'})
      }
    },
    selectedViewVal (val) {
      this.selectedDisplayView = val
      this.selectedUserView = false
      this.selectedTimeView = false
      this.displayWiseFilter = val
      if(val === 'user') {
        let date = moment(this.scheduleStartDt)
        this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
        this.selectedView = 'agendaDay'
        this.itemsDynamic = []
        this.groupsDynamic = []
        this.getTeamList();
        this.getDayOneJobVisist();
      }
      if(val === 'time') {
       this.selectedView = 'agendaDay'
        this.itemsDynamic = []
        this.groupsDynamic = []
        this.getTeamList()
          this.getJoblist();
      }
      this.manageRouterQuery()
    },
    getTeamList() {
      // console.log('call userFilterlist ------------------------', this.userFilterlist);
        MyJobApp.getEmployeeList(
        "active",
        (response) => {
          if (this.userFilterlist.length > 0) {
              response.Data.tableRow.forEach((user) => {
                this.userFilterlist.forEach(id => {
                  if (user.userDetailId === parseInt(id)) {
                    let group = {};
                    group.id = user.userDetailId;
                    group.content = user.firstName + ' ' + user.lastName;
                    this.groupsDynamic.push(group);
                  }
                })
              })
          } else {
            response.Data.tableRow.forEach((user) => {
              let group = {};
              group.id = user.userDetailId;
              group.content = user.firstName + ' ' + user.lastName;
              this.groupsDynamic.push(group);
            })
          }
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getUserFilter() {
      MyJobApp.getEmployeeList(
        "active",
        (response) => {
          let empLi = response.Data.tableRow !== null ? response.Data.tableRow : [] 

          for (let index = 0; index < empLi.length; index++) {
            empLi[index].selected = false
            empLi[index].fullName = empLi[index].firstName + ' ' + empLi[index].lastName.substr(0, 1)
          }

          if (this.applyedUserFilters !== "") {
            // // console.log('+++++++++++++++++++++++++++', this.applyedUserFilters);
            let arr = this.applyedUserFilters.split(',')
            empLi.forEach(a => {
              a.selected =  false
              let fIndex = arr.findIndex(x => parseInt(x) === a.userDetailId)
              if (fIndex >= 0) {
                // // console.log("ffffffffffffffffffffffffffffffff", fIndex);
                a.selected = true
              }
            })
              var abc = null
              if (empLi.length === arr.length) {
                const newFirstElement = {
                  userDetailId: 0,
                  fullName: "All",
                  selected: true
                }
                abc = [newFirstElement].concat(empLi)
              } else {
                const newFirstElement = {
                  userDetailId: 0,
                  fullName: "All",
                  selected: false
                }
                abc = [newFirstElement].concat(empLi)
              }

              this.employeeList = abc


              let list = []
            this.employeeList.forEach(emp=> {
              if (emp.selected && emp.userDetailId) {
                list.push(emp)
              }
            })


            if (this.selectedDisplayView === 'time') {
              let obj = {
                userDetailId: 1,
                firstName: 'All'
              }
              list.push(obj)
              this.teamListData = list
            } else {
              this.teamListData = list
            }
            
          }
           else {
            const newFirstElement = {
              userDetailId: 0,
              fullName: "All",
              selected: false
            }
            this.employeeList = [newFirstElement].concat(empLi)
            let arr = []
            this.employeeList.forEach(emp=> {
              if (emp.selected && emp.userDetailId) {
                arr.push(emp)
              }
            })
            if (this.selectedDisplayView === 'time') {
              let arr = []
              let obj = {
                userDetailId: 1,
                firstName: 'All'
              }
              arr.push(obj)
              this.teamListData = arr
            } else {
              this.teamListData = arr
            }
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getDayOneJobVisist (iSsLoader) {
      this.itemsDynamic = []
      this.groupsDynamic = []
      if (this.scheduleStartDt === '') {
        this.scheduleStartDt = moment(new Date()).format('YYYY-MM-DD')
      }
      if (this.scheduleEndDt === '') {
        this.scheduleEndDt = moment(new Date()).format('YYYY-MM-DD')
      }
      let temp = moment(new Date(this.scheduleStartDt)).format('YYYY-MM-DD');
      temp = temp + " 00:00"
      let startDt = new Date(temp).toUTCString();
      let endDt = new Date(new Date(this.scheduleEndDt).setHours(23, 59, 0, 0)).toUTCString()
      if (!iSsLoader) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      }
      this.dataLoaded = MyJobApp.UserScheduleJobVisit(
        startDt,
        endDt,
        this.userFilterlist.toString(),
        (response) => {
        this.empArray = response.Data.AssignToLastLocList !== null ? response.Data.AssignToLastLocList  : []
        let temp = response.Data.JobList !== null ? response.Data.JobList  : []
        temp.forEach((data) => {
          if (data.entityType !== '') {
            let item = {};
            let idObj = {
              jobId: data.jobId,
              userDetailId: data.userDetailId,
              entityType: data.entityType,
              visitId: data.visitId,
              startTime: data.start,
              endTime: data.end
            };
            item.id = JSON.stringify(idObj);
            item.group = data.userDetailId;
            item.oldAssignee = data.userDetailId;
            item.className = data.entityType
            item.content = `<div  style='float:right;'><p style='margin: 0'>${moment(new Date(data.start)).format('HH:mm')} - ${moment(new Date(data.end)).format('HH:mm')}</p><span style='font-size:11px; font-weight: 300; font-color:#eee;'>${data.title}(${data.entityType}#${data.jobId})<span><p style='margin: 0'>${data.summary.split('for')[0]}</p></div>`;
            item.start = data.start;
            item.end = data.end;
            item.obj = data;
            item.style = "height: auto; cursor: pointer";
            item.selectable = true;
            item.itemsAlwaysDraggable = true;
            this.itemsDynamic.push(item);
          }
        })
        // console.log("itemsDynamic", this.itemsDynamic);
          this.getEventsList()
          this.selectedDisplayView = 'user'
          this.selectedUserView = true
            this.isLoad = false
          if (!iSsLoader) {
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      }
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getEventsList() {
      let temp = moment(new Date(this.scheduleStartDt)).format('YYYY-MM-DD');
      temp = temp + " 00:00"
      let startDt = new Date(temp).toUTCString();
      let endDt = new Date(new Date(this.scheduleEndDt).setHours(23, 59, 0, 0)).toUTCString()
      MyJobApp.ScheduleJobVisit(
        startDt,
        endDt,
        this.userFilterlist.toString(),
        (response) => {
        let temp = response.Data.JobList !== null ? response.Data.JobList  : []
        this.eventList = temp
        // console.log('check **************************************************************************', this.eventList);
        this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
        }
      );
    },
    getJoblist(iSsLoader) {
      this.itemsDynamic = []
      this.groupsDynamic = []
      let temp = moment(new Date(this.scheduleStartDt)).format('YYYY-MM-DD');
      temp = temp + " 00:00"
      let startDt = new Date(temp).toUTCString();
      let endDt = new Date(new Date(this.scheduleEndDt).setHours(23, 59, 0, 0)).toUTCString()
      if (!iSsLoader) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      }
      this.dataLoaded = MyJobApp.ScheduleJobVisit(
        startDt,
        endDt,
        this.userFilterlist.toString(),
        (response) => {
        let temp = response.Data.JobList !== null ? response.Data.JobList  : []
        this.empArray = response.Data.AssignToLastLocList !== null ? response.Data.AssignToLastLocList  : []
        this.eventList = temp
        if (temp.length !== 0 ) {
          temp.forEach((data) => {
            if (data.entityType !== 'task') {
              let item = {};
              let idObj = {
                jobId: data.jobId,
                userDetailId: data.userDetailId,
                entityType: data.entityType,
                visitId: data.visitId,
                startTime: data.start,
                endTime: data.end
              };
              item.id = JSON.stringify(idObj);
              item.group = 'all';
              item.className = data.entityType
              item.oldAssignee = data.userDetailId;
              item.content = `<div  style='float:right;'><p style='margin: 0'>${moment(new Date(data.start)).format('HH:mm')} - ${moment(new Date(data.end)).format('HH:mm')}</p><span style='font-size:11px; font-weight: 300; font-color:#eee;'>${data.title}(${data.entityType}#${data.jobId})<span><p style='margin: 0'>${data.summary.split('for')[0]}</p><p style='margin: 0'>${data.assignToList}</p></div>`;
              item.start = data.start;
              item.end = data.end;
              item.obj = data;
              item.style = "height: auto; cursor: pointer";
              item.selectable = true;
              item.itemsAlwaysDraggable = true;
              this.itemsDynamic.push(item);
            } else {
              let item = {};
              let idObj = {
                jobId: data.taskId,
                userDetailId: data.userDetailId,
                entityType: data.entityType,
                visitId: data.visitId,
                startTime: data.startTime,
                endTime: data.endTime
              };
              item.id = JSON.stringify(idObj);
              item.group = 'all';
              item.className = data.entityType
              item.oldAssignee = data.userDetailId;
              item.content = `<div  style='float:right;'><p style='margin: 0'>${moment(new Date(data.startTime)).format('HH:mm')} - ${moment(new Date(data.endTime)).format('HH:mm')}</p><span style='font-size:11px; font-weight: 300; font-color:#eee;'>${data.title}(${data.entityType}#${data.taskId})<span><p style='margin: 0'>${data.summary.split('for')[0]}</p><p style='margin: 0'>${data.assignToList}</p></div>`;
              item.start = data.startTime;
              item.end = data.endTime;
              item.obj = data;
              item.style = "height: auto; cursor: pointer";
              item.selectable = true;
              item.itemsAlwaysDraggable = true;
              this.itemsDynamic.push(item);
            }
          })
        }
          this.selectedDisplayView = 'time'
          this.selectedTimeView = true
            this.isLoad = false
          if (!iSsLoader) {
              this.$store.dispatch("SetLoader", { status: false, message: "" });
          } else {
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    createNewJob () {
      let temp = FilterPermissions('job')
      if (temp) {
        this.$router.push({name: 'addNewJob', query: {startTime: JSON.stringify(this.newJobObj.selectedStartTime), endTime: JSON.stringify(this.newJobObj.selectedEndTime)}})
        // this.$router.push({name: 'scheduleAddJob', query: {startTime: JSON.stringify(this.newJobObj.selectedStartTime), endTime: JSON.stringify(this.newJobObj.selectedEndTime)}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to create Job`})
      }
    },
    createTask () {
      let temp = FilterPermissions('task')
      if (temp) {
        this.$router.push({name: 'AddNewTask', params: {taskId: 0}, query: {startTime: JSON.stringify(this.newJobObj.selectedStartTime), endTime: JSON.stringify(this.newJobObj.selectedEndTime)}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to create Task`})
      }
    },
    existingJob () {
      let temp = FilterPermissions('job')
      if (temp) {
        this.createJobPopup = false
        this.searchJobPopup = true
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to create Job`})
      }
    },
    cancelPopup () {
      this.createJobPopup = false
      this.newJobObj.selectedEndTime = ''
      this.newJobObj.selectedStartTime = ''
      this.newJobObj.dateTitle = ''
    },
    closeDatePicker () {
      this.selectedDatePicker = false
    },
    updateDate () {
      if (this.currentViewDate !== '') {
        this.selectedDatePicker = false
        this.selectedDateByPicker(this.currentViewDate)
      }
    },
    selectedDateByPicker (val) {
      if (this.selectedDisplayView === 'user') {
        if (val !== undefined) {
          this.selectedUserView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let date = moment(val)
          this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
          this.scheduleStartDt = val
          this.scheduleEndDt = val
          this.options.min = val
          let d = new Date(this.scheduleEndDt)
          let endDate =  d.setDate(d.getDate() + 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.max = endDate
          this.getTeamList();
          this.getDayOneJobVisist(true);
        } else {
          this.selectedUserView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let todayDate = moment(new Date()).format('YYYY-MM-DD')
          let date = moment(todayDate)
          this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
          // // console.log("!!!!!!!!!!!!!!!!1", this.showTiltle);
          this.scheduleStartDt = todayDate
          this.scheduleEndDt = todayDate
          this.options.min = todayDate
          let d = new Date(this.scheduleEndDt)
          let endDate =  d.setDate(d.getDate() + 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.max = endDate
          this.getTeamList();
          this.getDayOneJobVisist(true);
        }
      }
      if (this.selectedDisplayView === 'time') {
        if (val !== undefined) {
          this.selectedTimeView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let date = moment(val)
          this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
          this.scheduleStartDt = val
          this.scheduleEndDt = val
          this.options.min = val
          let d = new Date(this.scheduleEndDt)
          let endDate =  d.setDate(d.getDate() + 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.max = endDate
          this.getTeamList()
          this.getJoblist(true);
        } else {
          this.selectedTimeView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let todayDate = moment(new Date()).format('YYYY-MM-DD')
          let date = moment(todayDate)
          this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
          this.scheduleStartDt = todayDate
          this.scheduleEndDt = todayDate
          this.options.min = todayDate
          let d = new Date(this.scheduleEndDt)
          let endDate =  d.setDate(d.getDate() + 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.max = endDate
          this.getTeamList();
          this.getJoblist(true);
        }
      }
      if (moment(new Date()).format('YYYY-MM-DD') === moment(val).format('YYYY-MM-DD')) {
        this.hideBtn = true
      } else {
        this.hideBtn = false}
        
      this.manageRouterQuery()
    },
      
    goDateCall (date) {
      // this.$root.$emit('setFullScreenLoader', true)
      // console.log("current date----------------- 11 ------------------------");
      if (date === 'next') {
        if (this.selectedDisplayView === 'user') {
          this.selectedTimeView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let d = new Date(this.scheduleStartDt)
          let nextDate =  d.setDate(d.getDate() + 1)
          nextDate = moment(new Date(nextDate).toString()).format('YYYY-MM-DD')
          this.scheduleStartDt = nextDate
          this.currentViewDate = this.scheduleStartDt
          this.scheduleEndDt = this.scheduleStartDt
          let c = new Date(this.scheduleEndDt)
          let endDate =  c.setDate(c.getDate() + 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.min = this.scheduleStartDt
          this.options.max = endDate
          this.selectedDateByPicker(this.scheduleStartDt)
        }
      }
      if (date === 'prev') {
        if (this.selectedDisplayView === 'user') {
          this.selectedTimeView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let d = new Date(this.scheduleStartDt)
          let nextDate =  d.setDate(d.getDate() - 1)
          nextDate = moment(new Date(nextDate).toString()).format('YYYY-MM-DD')
          this.scheduleStartDt = nextDate
          this.currentViewDate = this.scheduleStartDt
          this.scheduleEndDt = this.scheduleStartDt
          let c = new Date(this.scheduleEndDt)
          let endDate =  c.setDate(c.getDate() - 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.min = this.scheduleStartDt
          this.options.max = endDate
          this.selectedDateByPicker(this.scheduleStartDt)
        }
      }
      if (date === 'next') {
        if (this.selectedDisplayView === 'time') {
          this.selectedUserView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let d = new Date(this.scheduleStartDt)
          let nextDate =  d.setDate(d.getDate() + 1)
          nextDate = moment(new Date(nextDate).toString()).format('YYYY-MM-DD')
          this.scheduleStartDt = nextDate
          this.currentViewDate = this.scheduleStartDt
          this.scheduleEndDt = this.scheduleStartDt
          let c = new Date(this.scheduleEndDt)
          let endDate =  c.setDate(c.getDate() + 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.min = this.scheduleStartDt
          this.options.max = endDate
          this.selectedDateByPicker(this.scheduleStartDt)
        }
      }
      if (date === 'prev') {
        if (this.selectedDisplayView === 'time') {
          this.selectedUserView = false
          this.itemsDynamic = []
          this.groupsDynamic = []
          let d = new Date(this.scheduleStartDt)
          let nextDate =  d.setDate(d.getDate() - 1)
          nextDate = moment(new Date(nextDate).toString()).format('YYYY-MM-DD')
          this.scheduleStartDt = nextDate
          this.currentViewDate = this.scheduleStartDt
          this.scheduleEndDt = this.scheduleStartDt
          let c = new Date(this.scheduleEndDt)
          let endDate =  c.setDate(c.getDate() - 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.min = this.scheduleStartDt
          this.options.max = endDate
          this.selectedDateByPicker(this.scheduleStartDt)
        }
      }
      this.manageRouterQuery()
    },
    toggleActiveAndInactiveB (index) {
      this.userFilterlist = []
      let filter = []
      let reload = false
      if (this.employeeList[index].fullName.toLowerCase() === 'all') {
        if (!this.employeeList[0].selected) {
          this.$store.dispatch("SetLoader", {status: true,message: "Loading..."});
          reload = true
          this.employeeList[0].selected = true
          for (let index = 0; index < this.employeeList.length; index++) {
            this.employeeList[index].selected = true
          }
        } else {
          this.employeeList[0].selected = false
          for (let index = 0; index < this.employeeList.length; index++) {
            this.employeeList[index].selected = false
          }
        }
      } else {
        reload = true
        this.$store.dispatch("SetLoader", {status: true,message: "Loading..."});
        this.employeeList[index].selected = !this.employeeList[index].selected
      }

      this.employeeList.forEach(e => {
        if (e.fullName.toLowerCase() !== 'all' && e.selected) {
           filter.push(e)
        }
      });

      if (filter.length === this.employeeList.length - 1 ) {
        this.employeeList[0].selected = true
      } else {
        this.employeeList[0].selected = false
      }
        this.employeeList.map(emp=> {
          if (emp.selected && emp.userDetailId) {
            this.userFilterlist.push(emp.userDetailId)
          }
        })
      
      setTimeout(() => {
        if (reload) {
          this.getFilterWiseData()
        }
      }, 1000);
      this.manageRouterQuery()
    },
    getFilterWiseData () {
      this.isLoad = true
      this.getTeamList()
      if (this.selectedDisplayView === 'time') {
        this.getJoblist()
      } else {
        this.getDayOneJobVisist()
      }
      
    },
    loadRouterWise () {
      this.isLoad = true
        this.selectedUserView = false
      this.selectedTimeView = false
      // console.log('check pppppppppppppppppppppppppppppppppppp');
      if (this.$route.query.subview !== undefined) {
      if (this.$route.query.subview !== '') {
        this.selectedDisplayView = this.$route.query.subview
      }
    }
    if (this.$route.query.etime !== undefined) {
      if (this.$route.query.etime !== '') {
        this.scheduleEndDt = this.$route.query.etime
          let d = new Date(this.$route.query.etime)
          let endDate =  d.setDate(d.getDate() + 1)
          endDate = moment(new Date(endDate).toString()).format('YYYY-MM-DD')
          this.options.max = endDate
      }
    }
    if (this.$route.query.user !== undefined) {
      if (this.$route.query.user !== '') {
        this.applyedUserFilters = this.$route.query.user
           this.userFilterlist = this.$route.query.user.split(',')
      }
    }
    if (this.$route.query.stime !== undefined) {
      if (this.$route.query.stime !== '') {
        this.options.min = this.$route.query.stime
        this.scheduleStartDt = this.$route.query.stime
        this.startTime = this.$route.query.stime
         let todayDate = moment(new Date(this.$route.query.stime)).format('YYYY-MM-DD')
        let date = moment(todayDate)
        this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
        if (moment(new Date()).format('YYYY-MM-DD') === moment(this.$route.query.stime).format('YYYY-MM-DD')) {
          this.hideBtn = true
        } else {
          this.hideBtn = false
        }
      } else{
         let todayDate = moment(new Date()).format('YYYY-MM-DD')
        let date = moment(todayDate)
        this.showTiltle = '(' + date.format('ddd') + ') ' + date.format('MMM, DD')
      }
    }

    this.itemsDynamic = []
      this.groupsDynamic = []

      

      // console.log('>>>>>>>>>>>>>>>  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.$route.query)
      // console.log('>>>>>>>>>>>>>>>  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.selectedDisplayView)

      


    if (this.selectedDisplayView === 'user') {
      this.getUserFilter();
      this.selectedUserView = true
      this.selectedTimeView = false
      this.displayWiseFilter = 'user'
      this.getTeamList();
      this.getDayOneJobVisist();
      // console.log("cuureeee -ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss");
    } else {
      this.selectedUserView = false
      this.selectedTimeView = true
      this.displayWiseFilter = 'time'
      this.getTeamList()
      this.getUserFilter()
      this.getJoblist();
      // console.log("cuureeee -----------------------------------------------------------------------------------------------------------------------");
    }
    },
    getUnSchJobs () {
          this.$store.dispatch("SetLoader", {status: true,message: "Loading..."});
          MyJobApp.GetUnScheduleJobsOnly(
            (response) => {
              this.unscheduleventList = response.Data.JobList !== null ? response.Data.JobList  : []
              this.$store.dispatch("SetLoader", { status: false, message: "" });
            },
            () => {
              this.resetPaginationValues = false
              this.$store.dispatch("SetLoader", { status: false, message: "" });
            }
          );
        },
  },
  beforeDestroy() {
    document.body.style = 'overflow: visible;'
    this.$root.$off("addExistingJobHandler");
    this.$root.$off("closeScheduleJobSel");
    window.removeEventListener('popstate', this.loadRouterWise);
  },
};
</script>

<style>
.fc-center {
  display: none !important;
}
.fc-scroller-canvas {
  width: 100% !important;
  min-width: 100% !important;
  width: auto;
  min-width: min-content !important;
}


.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1295BA;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.progress-bar {
  background-color: rgba(156, 156, 156, 0.691);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  height: 100%;
  background-color: rgb(18,149,186);
  transform-origin: 0% 50%;
}

.vis-item.vis-selected {
    border: 2px solid #DC2626 !important;
}
</style>